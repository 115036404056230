// Welcome to Code in Framer
// Get Started: https://www.framer.com/developers/

import Example from "https://framer.com/m/framer/Example.js@^1.0.0"
import React, { useEffect, useRef, useState } from "react"
/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */

export default function Countdown(props) {
    var tzoffset = new Date().getTimezoneOffset() * 60000 //offset in milliseconds
    var timeIn24hours = new Date(new Date().getTime() + 86400000 - tzoffset)
        .toISOString()
        .slice(0, -1)
    console.log(timeIn24hours) //2023-09-09T03:00:13.092

    if (!localStorage.getItem("ISOIn24Hours")) {
        // localStorage.setItem("ISONow", new Date().toISOString())
        localStorage.setItem("ISOIn24Hours", timeIn24hours)
    }

    // new Date(localStorage.getItem("ISONow")).getTime() -
    //     new Date(localStorage.getItem("ISOIn24Hours")).getTime()
    // const diffInMs = new Date().getTime() - new Date(timeIn24hours).getTime();
    // console.log(diffInMs);

    const [countdownDate, setCountdownDate] = useState(
        // new Date().getTime() -
        new Date(localStorage.getItem("ISOIn24Hours")).getTime()
    )

    // : new Date().getTime() + 86400000

    const [state, setState] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    })

    useEffect(() => {
        setInterval(() => setNewTime(), 1000)
    }, [])

    const setNewTime = () => {
        if (countdownDate) {
            const currentTime = new Date().getTime()

            const distanceToDate = countdownDate - currentTime

            let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24))
            let hours = Math.floor(
                (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            )
            let minutes = Math.floor(
                (distanceToDate % (1000 * 60 * 60)) / (1000 * 60)
            )
            let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000)

            const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9]

            days = `0${days}`
            if (numbersToAddZeroTo.includes(hours)) {
                hours = `0${hours}`
            } else if (numbersToAddZeroTo.includes(minutes)) {
                minutes = `0${minutes}`
            } else if (numbersToAddZeroTo.includes(seconds)) {
                seconds = `0${seconds}`
            }

            setState({ days: days, hours: hours, minutes, seconds })
        }
    }

    return (
        <div>
            <div className="countdown-wrapper" style={textStyle}>
                <div className="time-section">
                    <div className="time">{state.days || "00"}</div>
                </div>
                <div className="time-section">
                    <div className="time">:</div>
                </div>
                <div className="time-section">
                    <div className="time">{state.hours || "00"}</div>
                </div>
                <div className="time-section">
                    <div className="time">:</div>
                </div>
                <div className="time-section">
                    <div className="time">{state.minutes || "00"}</div>
                </div>
                <div className="time-section">
                    <div className="time">:</div>
                </div>
                <div className="time-section">
                    <div className="time">{state.seconds || "00"}</div>
                </div>
            </div>
        </div>
    )
}

// Styles are written in object syntax
// Learn more: https://reactjs.org/docs/dom-elements.html#style
const textStyle = {
    color: "var(--token-fa312d7f-9ffe-4913-8337-46686bcf3920, rgb(255, 207, 37))",
    fontSize: "24px",
    fontFamily: "Orbitron, sans-serif",
    fontStyle: "normal",
    fontWeight: "800",
    textAlign: "center",
    letterSpacing: "4px",
    lineHeight: "1",
    margin: "0px",
    padding: "0px",
    display: "flex",
}
